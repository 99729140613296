<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      size="xl"
      hide-footer
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
    >
      <template #default="sProps">
        <div class="container" v-if="order">
          <div class="row">
            <div
              v-if="!order.last_email_send_status"
              class="col-12"
            >
              <div class="alert alert-secondary" role="alert">
                <i class="icon-error_outline"></i>Last email sent to this orders owner was unsuccessful.
              </div>
            </div>

            <!--     /Item Details       -->
            <div class="col-lg-5 col-sm-12 col-12">
              <div class="lead">Item Details</div>
              <div class="item-summery">
                <div class="item-summery-header">
                  <div class="order-type">
                    <p class="order-type text-uppercase">Type : {{order.type}}</p>
                  </div>
                  <div class="date-time">
                    <p v-if="order.requested_time_is_asap" class="time">Requested : ASAP</p>
                    <p v-else class="time">Requested : {{ dateFormat(order.requested_delivery_time) }}</p>
                    <p class="date">Ordered : {{ dateFormat(order.created_at) }}</p>
                  </div>
                </div>
                <div
                  class="item-summery-content"
                  v-for="(item, index) in order.items"
                  :key="`item${index}`"
                >
                  <div class="content">
                    <p class="ordered-item">{{ `${item.qty} x ${item.name}` }}</p>
                    <p class="ordered-item-price">{{ (item.price * item.qty) | currencySymbol}}</p>
                  </div>
                  <div
                    class="content text-info"
                    v-for="(addon, idx) in item.addons"
                    :key="`addon${index}_${idx}`"
                  >
                    <p class="ordered-item addon-name">{{ `${addon.name}` }}</p>
                    <p class="ordered-item-price">{{ (addon.price * item.qty) | currencySymbol }}</p>
                  </div>
                </div>
                <div class="item-summery-footer">
                  <div class="billing-item">
                    <p class="bill-type">Items</p>
                    <p class="bill-price">{{ itemsTotal | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Addons</p>
                    <p class="bill-price">{{ addonsTotal | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Sub Total</p>
                    <p class="bill-price">{{ getSubTotal() | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Delivery Charge</p>
                    <p class="bill-price">{{ order.delivery_amount | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Discount</p>
                    <p class="bill-price">{{ order.discount_amount | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Coupon</p>
                    <p class="bill-price">{{ order.coupon_amount | currencySymbol }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Points Used</p>
                    <p class="bill-price">{{ `${order.points_used} (${currencySymbol}${order.points_amount})` }}</p>
                  </div>
                  <div
                    v-if="order.adjustment_amount > 0"
                    class="billing-item">
                    <p class="bill-type">Adjustment</p>
                    <p class="bill-price">{{ `${currencySymbol}${order.adjustment_amount}` }}</p>
                  </div>
                  <div class="billing-item">
                    <p class="bill-type">Grand Total</p>
                    <p class="bill-price">{{ order.total | currencySymbol }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--     /Item Details       -->

            <!--     User Details       -->
            <div class="col-lg-7 col-sm-12 col-12 pc-only">
              <div class="lead">Customer Details</div>
              <div class="table-responsive">
                <table class="table table-active">
                  <tbody>
                  <tr>
                    <td colspan="2" class="divider">Personal Info</td>
                  </tr>
                  <tr>
                    <td class="key">Name</td>
                    <td class="val">{{ order.customer_name }}</td>
                  </tr>
                  <tr>
                    <td class="key">Phone</td>
                    <td class="val">{{ order.customer_phone }}</td>
                  </tr>
                  <tr>
                    <td class="key">Email</td>
                    <td class="val">{{ order.customer_email }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="divider">Address Info</td>
                  </tr>
                  <tr>
                    <td class="key">House No.</td>
                    <td class="val">{{ order.house_no }}</td>
                  </tr>
                  <tr>
                    <td class="key">Street</td>
                    <td class="val">{{ order.street_name }}</td>
                  </tr>
                  <tr>
                    <td class="key">County</td>
                    <td class="val">{{ order.county }}</td>
                  </tr>
                  <tr>
                    <td class="key">City</td>
                    <td class="val">{{ order.city }}</td>
                  </tr>
                  <tr>
                    <td class="key">Postcode</td>
                    <td class="val">{{ order.postcode }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--     /User Details       -->
          </div>
        </div>

        <!--   Accordion     -->
        <div v-if="order" class="accordion toggle-icons lg" id="toggleIcons">

          <div class="accordion-container customer">
            <div class="accordion-header" id="toggleIconsOne">
              <a href="#" class="collapsed" data-toggle="collapse" data-target="#toggleIconsCollapseOne" aria-expanded="false" aria-controls="toggleIconsCollapseOne">
                Customer Details
              </a>
            </div>
            <div id="toggleIconsCollapseOne" class="collapse" aria-labelledby="toggleIconsOne" data-parent="#toggleIcons" style="">
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table table-active">
                    <tbody>
                    <tr>
                      <td colspan="2" class="divider">Personal Info</td>
                    </tr>
                    <tr>
                      <td class="key">Name</td>
                      <td class="val">{{ order.customer_name }}</td>
                    </tr>
                    <tr>
                      <td class="key">Phone</td>
                      <td class="val">{{ order.customer_phone }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="divider">Address Info</td>
                    </tr>
                    <tr>
                      <td class="key">House No.</td>
                      <td class="val">{{ order.house_no }}</td>
                    </tr>
                    <tr>
                      <td class="key">Street</td>
                      <td class="val">{{ order.street_name }}</td>
                    </tr>
                    <tr>
                      <td class="key">County</td>
                      <td class="val">{{ order.county }}</td>
                    </tr>
                    <tr>
                      <td class="key">City</td>
                      <td class="val">{{ order.city }}</td>
                    </tr>
                    <tr>
                      <td class="key">Postcode</td>
                      <td class="val">{{ order.postcode }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-container">
            <div class="accordion-header" id="toggleIconsTwo">
              <a href="#" class="collapsed" data-toggle="collapse" data-target="#toggleIconsCollapseTwo" aria-expanded="false" aria-controls="toggleIconsCollapseTwo">
                Order Details
              </a>
            </div>
            <div id="toggleIconsCollapseTwo" class="collapse" aria-labelledby="toggleIconsTwo" data-parent="#toggleIcons">
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table table-active">
                    <tbody>
                    <tr>
                      <td colspan="2" class="divider">Summery</td>
                    </tr>
                    <tr>
                      <td class="key">Type</td>
                      <td class="val">{{ order.type }}</td>
                    </tr>
                    <tr>
                      <td class="key">Items</td>
                      <td class="val">
                        {{ order.items.length ? order.items.length : 0}}
                        <span class="help">(excluding qty. & addons)</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="key">Note</td>
                      <td class="val">{{ order.note }}</td>
                    </tr>
                    <tr>
                      <td class="key">Ordered at</td>
                      <td class="val">{{ dateFormat(order.created_at) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Requested for</td>
                      <td class="val">{{ dateFormat(order.requested_delivery_time) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Delivered at</td>
                      <td class="val" v-if="order.delivery_time">{{ dateFormat(order.delivery_time) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Last Updated</td>
                      <td class="val">{{ dateFormat(order.updated_at) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Status</td>
                      <td class="val text-uppercase" v-html="renderStatus(order.status)"></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-container">
            <div class="accordion-header" id="toggleIconsThree">
              <a href="#" class="collapsed" data-toggle="collapse" data-target="#toggleIconsCollapseThree" aria-expanded="false" aria-controls="toggleIconsCollapseThree">
                Payment Details
              </a>
            </div>
            <div id="toggleIconsCollapseThree" class="collapse" aria-labelledby="toggleIconsThree" data-parent="#toggleIcons">
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table table-active">
                    <tbody>
                    <tr>
                      <td class="key">Subtotal</td>
                      <td class="val">{{ currencySymbol }} {{ order.subtotal.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Delivery Charge</td>
                      <td class="val">
                        <i class="icon-plus"></i> £ {{ order.delivery_amount.toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="key">Discount</td>
                      <td class="val">
                        <i class="icon-minus"></i> {{ currencySymbol }} {{ order.discount_amount.toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="key">Coupon</td>
                      <td class="val">
                        <i class="icon-minus"></i> {{ currencySymbol }} {{ order.coupon_amount.toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="key">Total</td>
                      <td class="val">{{ currencySymbol }} {{ order.total.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="key">Payment Type</td>
                      <td class="val text-uppercase">{{ order.payment_type }}</td>
                    </tr>
                    <tr>
                      <td class="key">Payment Status</td>
                      <td class="val text-uppercase">
                        <badge-comp v-if="order.status_payment" type="su">
                          Paid
                        </badge-comp>
                        <badge-comp v-else type="da">
                          Due
                        </badge-comp>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
<!--   /Accordion     -->

      </template>
    </modal-comp>
  </div>
</template>

<script>
import FormErrorMsg from '../Utility/FormErrorMsg';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import { formatISODate } from '../../js/helpers/Helper';
import { CURRENCY_SYMBOL, DATETIME_FORMAT_1, SITE_TIMEZONE } from '../../js/helpers/Constants';
import BadgeComp from '../Utility/BadgeComp';

export default {
  name: "order-action",
  components: {BadgeComp, FormErrorMsg, Treeselect, ModalComp, FormInputHelp},
  props: {
    id: {
      type: String,
      required: true
    },
    order: {
      type: [Object, Boolean],
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  filters : {
    currencySymbol(amount) {
      return `${CURRENCY_SYMBOL}${ amount? amount.toFixed(2) : 0}`
    },
    formatDate(date) {
      return formatISODate(date, DATETIME_FORMAT_1, SITE_TIMEZONE);
    },
  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'Order Details',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Save Changes'
      },

      itemsTotal: 0,
      addonsTotal: 0,

      currencySymbol: CURRENCY_SYMBOL
    };
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
  },

  methods: {

    getSubTotal() {

      let itemsTotalCount = 0;
      let addonsTotalCount = 0;

      const total = this.order.items
        .reduce((n, item) => {

          const itemsTotal = item.price * item.qty;

          const addonsTotal = item.addons.reduce((total, addon) => {
              return total + (addon.price * item.qty);
          }, 0);

          // set totals separately
          itemsTotalCount += itemsTotal;
          addonsTotalCount += addonsTotal;

          return n + itemsTotal + addonsTotal;

        }, 0)
      ;

      this.itemsTotal = itemsTotalCount;
      this.addonsTotal = addonsTotalCount;

      return total;

    },

    openModal() {
      this.showModal = true;
    },

    renderStatus(status) {

      // available statuses: 'pending','processing','delivering','delivered','cancelled'

      switch (status) {
        case 'pending':
          return `<span class="badge table-badge badge-dark badge-pill text-capitalize">${status}</span>`;

        case 'processing':
          return `<span class="badge table-badge badge-light badge-pill text-capitalize">${status}</span>`;

        case 'delivering':
          return `<span class="badge table-badge badge-primary badge-pill text-capitalize">${status}</span>`;

        case 'delivered':
          return `<span class="badge table-badge badge-success badge-pill text-capitalize">${status}</span>`;

        case 'cancelled':
          return `<span class="badge table-badge badge-secondary badge-pill text-capitalize">${status}</span>`;

        default:
          return `<span class="badge table-badge badge-danger badge-pill text-capitalize">${status}</span>`;
      }
    },

    dateFormat(timeString) {
      return formatISODate(timeString, DATETIME_FORMAT_1, SITE_TIMEZONE);
    }
  }
}
</script>

<style scoped lang="scss">
.key {
  background-color: #f8f8f8;
}

.val .help {
  color: darkgrey;
  font-size: 13px;
  font-style: italic;
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}
.item-summery-content .content {
  margin-bottom: 0;

  .addon-name {
    margin-left: 23px !important;
  }
}
.item-summery {
  row-gap: 8px;
}
</style>
