// list view actions
export const LIST_STATUS_CHANGE_ACTION_TYPE = 'statusChange';
export const LIST_EDIT_ACTION_TYPE = 'edit';
export const LIST_DELETE_ACTION_TYPE = 'delete';
export const LIST_PRINT_ACTION_TYPE = 'print';
export const CURRENCY_SYMBOL = '£';

/**
 * Time format like: 02:05 AM
 * @type String
 */
export const TIME_SELECTS_FORMAT = 'hh:mm a';

/**
 * Date format like: 2021-06-26
 * @type String
 */
export const DATE_SELECTS_FORMAT = 'yyyy-MM-dd';
export const SITE_TIMEZONE = process.env.MIX_SITE_TIMEZONE;

/**
 * DateTime format like: 27th Sep @06:14 PM
 * @type String
 */
export const DATETIME_FORMAT_1 = 'do MMM @hh:mm a';
