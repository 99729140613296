import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { SITE_TIMEZONE, TIME_SELECTS_FORMAT } from './Constants';

export const DateFns = dateFns;
export const DateFnsTz = dateFnsTz;

export const formatModalId = (id, prefix='mid') => `${prefix}${id}`;

export const getPermissionSubject = permissionName => {

    if (typeof permissionName !== 'string') throw new Error(`Can't get permission subject while permission name is not string`);

    const separator = '-';
    return permissionName.substr(0, permissionName.indexOf(separator));
};

/**
 * Use when the time string is in ISO format or want to change timezone
 * @param  timeString : String
 * @param [format=do MMM, yyyy] : String
 * @param [timeZone=null] : String
 * @returns String
 */
export const formatISODate = (timeString, format='do MMM, yyyy', timeZone=null) => {
  let timeDate = dateFns.parseJSON(timeString);

  if (timeZone !== null) {
    timeDate = DateFnsTz.utcToZonedTime(timeDate, timeZone);
  }

  return dateFns.format(timeDate, format);
};

/**
 * Use when the time string is complex or you want to pass its format for guaranteed accuracy
 * @param  timeString : String
 * @param [format=do MMM, yyyy] : String
 * @param [timeStringFormat=null] : String
 * @returns String
 */
export const formatDate = (timeString, format='do MMM, yyyy', timeStringFormat=null) => {

  const date = (timeStringFormat === null)
    ? new Date(timeString)
    : DateFns.parse(timeString, timeStringFormat, new Date())
  ;

  return dateFns.format(date, format);
};

/**
 * Apply timezone to a datetime string or date object
 * @param datetime : String|Date
 * @param [timezone=SITE_TIMEZONE] : String - app's timezone set in env
 * @returns Date
 */
export const applyTimezone = (datetime, timezone=SITE_TIMEZONE) => {
  const date = (typeof datetime === 'string')
    ? new Date(datetime)
    : datetime
  ;

  return DateFnsTz.utcToZonedTime(date, timezone);
};

export const formatTimePickerCompatibleTime = (timeString) => {
    const timeStr = `2020-12-12 ${timeString}`;
    return formatDate(timeStr, TIME_SELECTS_FORMAT);
};


export const userAvatarChar = (userName) => {

  if (!userName.length) return "";


  let nameSplit = userName.split(' ');

  let chars = '';
  if (nameSplit.length === 1) {
    chars += nameSplit[0][0]; // first char
    chars += nameSplit[nameSplit.length - 1][1]; // last char
    return chars;
  }

  chars += nameSplit[0][0]; // first char of first element
  chars += nameSplit[nameSplit.length - 1][0]; // first char of last element

  return chars;

}
