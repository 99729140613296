<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Orders</div>
          <switch-comp
            name="order-filter"
            :checked="filterEnabled"
            enable-text="Filter Enabled"
            disable-text="Filter Disabled"
            @change="filterEnabled=!filterEnabled"
          />
        </div>
        <div class="card-body pt-0">

          <transition name="slide-fade">
            <div v-if="filterEnabled" class="filters-panel">

              <div class="filters-controls row">
                <div class="form-group col-sm-12 col-md-12 col-lg-2">
                  <label>Customer</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Customer Name"
                    v-model="filters.customer"
                    @keyup="triggerFilter"
                  />
                </div>
                <div class="form-group col-sm-5 col-md-5 col-lg-2">
                  <label>From</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="From Date"
                    v-model="filters.dateFrom"
                    @change="triggerFilter"
                  />
                </div>
                <div class="form-group col-sm-5 col-md-5 col-lg-2">
                  <label>To</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="To Date"
                    v-model="filters.dateTo"
                    @change="triggerFilter"
                  />
                </div>
                <div class="form-group col-sm-2 col-md-2 col-lg-auto">
                  <button
                    type="button"
                    class="btn btn-primary mt-4 text-nowrap"
                    @click="filterSetToday"
                  >
                    Today
                  </button>
                </div>
                <div class="form-group col-sm-10 col-md-9 col-lg-2">
                  <label>Status</label>
                  <select
                    class="form-control"
                    v-model="filters.status"
                    @change="triggerFilter"
                  >
                    <option selected value="">Choose...</option>
                    <option
                      v-for="status in statuses"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-sm-2 col-md-3 col-lg-auto">
                  <button
                    type="button"
                    class="btn btn-secondary mt-4 text-nowrap"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </div>

              <div class="filters-summery row">
                <div class="col fs20">
                  Total <strong>{{ currencySymbol }}{{ filterTotalAmount }}</strong> in <strong>{{ filterTotalOrders }}</strong> orders
                </div>
              </div>

            </div>
          </transition>

          <!--list-->
          <data-table
            id="orders"
            :column-map="columnMap"
            :data="allOrders"
            click-action-class=".order-action"
            @actionClicked="handleAction"
          />

          <!--actions-->
          <order-action
            id="editOrder"
            :order="actionSubject.subject"
            :show="actionStatus.edit"
            @user-action-hide="()=> this.actionStatus.edit = false"
          />

          <status-update-action
            :show-modal="actionStatus.status && permissions.update"
            :available-options="statuses"
            :current-option="actionSubject.subject.status"
            :subjectId="actionSubject.subject.id"
            actionLink="/admin/order"
            @user-action-hide="hideUpdateAction"
          />

          <delete-action
            :confirm-body="`
                            This will delete the order permanently.
                            You can delete a category once it has no items.
                        `"
            :confirmation="true"
            :do-action="actionStatus.delete"
            :url-arguments="actionSubject.subject.id"
            endpoint="/admin/order"
            @cancel="()=>this.actionStatus.delete = false"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderAction from '../DoActions/OrderAction';
import DeleteAction from '../DoActions/DeleteAction';
import BadgeComp from '../Utility/BadgeComp';
import DataTable from '../Utility/DataTable';
import statusUpdateAction from '../DoActions/StatusUpdateAction'
import SwitchComp from '../Utility/SwitchComp';
import {
  CURRENCY_SYMBOL, DATE_SELECTS_FORMAT, DATETIME_FORMAT_1,
  LIST_DELETE_ACTION_TYPE,
  LIST_EDIT_ACTION_TYPE,
  LIST_PRINT_ACTION_TYPE,
  LIST_STATUS_CHANGE_ACTION_TYPE, SITE_TIMEZONE
} from '../../js/helpers/Constants';
import { formatDate, formatISODate } from '../../js/helpers/Helper';
import PrintComp from '../Utility/PrintComp';
import PopupListItemSelect from '../Utility/PopupListItemSelect';
import { DateFns } from '../../js/helpers/Helper';

export default {
  name: "orders-page-content",
  props: {
    orders: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  components: {
    PopupListItemSelect,
    PrintComp,
    OrderAction,
    DeleteAction,
    BadgeComp,
    DataTable,
    statusUpdateAction,
    SwitchComp
  },

  data() {
    return {
      filterEnabled: true,
      filters: {
        customer: '',
        dateFrom: null,
        dateTo: null,
        status: ''
      },
      filterTotalOrders: 0,
      filterTotalAmount: 0,

      allOrders: [],

      columnMap: [
        {data: 'user.name', title: 'Customer', render: this.renderUsername},
        {data: 'total', title: 'Cost', render: this.renderTotal},
        {data: 'requested_delivery_time', title: 'Requested', render: this.renderFormattedTime},
        {data: 'status', title: 'Status', render: this.renderStatus},
        {data: 'id', title: 'Actions', render: this.renderAction}
      ],

      actionStatus: {
        status: false,
        edit: false,
        delete: false,
        print: false,
      },

      actionSubject: {
        subject: false,
        action: false
      },

      currencySymbol: CURRENCY_SYMBOL
    }
  },

  watch: {
    filterEnabled(nv) {

      // if filter disabled remove all filter
      if (!nv) {
        this.resetFilter();
      }
    },

    allOrders(nv) {
      this.filterTotalOrders = nv.length;
      const total = nv.reduce((pv, cv) => {
        return (pv + cv.total);
      }, 0);

      this.filterTotalAmount = total.toFixed(2);
    },

    actionSubject({action, subject}) {

      if (action === LIST_STATUS_CHANGE_ACTION_TYPE) {
        this.actionStatus.status = true;
      }
      if (action === LIST_EDIT_ACTION_TYPE) {
        this.actionStatus.edit = true;
      }
      if (action === LIST_DELETE_ACTION_TYPE) {
        this.actionStatus.delete = true;
      }
      if (action === LIST_PRINT_ACTION_TYPE) {
        this.actionStatus.print = true;
        alert('Printing will be implemented soon');
      }
    }
  },

  mounted() {
    this.triggerFilter();
    this.listenForNewOrder();
  },

  methods: {

    hideUpdateAction(){
      this.actionStatus.status = false
    },

    handleAction({id, action}) {
      this.actionSubject = {
        subject: this.allOrders.find(itm => itm.id === id),
        action: action,
      };
    },

    renderUsername(name, view, row) {
      return row.last_email_send_status
        ? `<div>${name}</div>`
        : `<div class="text-secondary">${name}</div>`
      ;
    },

    renderTotal(total, view, row) {

      const gTotal = `${CURRENCY_SYMBOL} ${total.toFixed(2)}`

      return row.last_email_send_status
        ? `<div>${gTotal}</div>`
        : `<div class="text-secondary">${gTotal}</div>`
      ;
    },

    renderStatus(status, view, row) {

      // available statuses: 'pending','processing','delivering','delivered','cancelled'

      let badgeClass = '';

      switch (status) {
        case 'pending':
          badgeClass = 'badge-dark';
          break;

        case 'processing':
          badgeClass = 'badge-light';
          break;

        case 'delivering':
          badgeClass = 'badge-primary';
          break;

        case 'delivered':
          badgeClass = 'badge-success';
          break;

        case 'cancelled':
          badgeClass = 'badge-secondary';
          break;

        default:
          badgeClass = 'badge-danger';
      }

      return `
                <span
                    class="badge table-badge ${badgeClass} badge-pill text-capitalize order-action"
                    data-action="${LIST_STATUS_CHANGE_ACTION_TYPE}"
                    data-action-value="${row.id}"
                    >${status}</span>
            `;
    },

    /**
     * RETURNING ELEMENT MUST HAVE ".action" CLASS FOT IT TO WORK
     * @param data
     * @param type
     * @param row
     * @returns {string}
     */
    renderAction(data) {

      let actions = `
          <span
            type="button"
            class="order-action list-action"
            data-action="${LIST_EDIT_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-archive"></i>
          </span>
        `;

      if (this.permissions.delete) {
        actions += `
          <span
            type="button"
            class="order-action list-action"
            data-action="${LIST_DELETE_ACTION_TYPE}"
            data-action-value="${data}"
          >
            <i class="icon-delete_sweep"></i>
          </span>
        `;
      }

      actions += `
        <span
            type="button"
            class="order-action list-action"
            data-action="${LIST_PRINT_ACTION_TYPE}"
            data-action-value="${data}"
        >
          <i class="icon-print"></i>
        </span>
      `;

      return actions;
    },

    renderFormattedTime(data, display, row) {

      if (row.requested_time_is_asap) {
        return row.last_email_send_status
          ? `<div>ASAP</div>`
          : `<div class="text-secondary">ASAP</div>`
        ;
      }

      const timeString = formatISODate(data, DATETIME_FORMAT_1, SITE_TIMEZONE);

      return row.last_email_send_status
        ? `<div>${timeString}</div>`
        : `<div class="text-secondary">${timeString}</div>`
      ;
    },

    listenForNewOrder() {
      Echo
        .private('orderCh.new-order')
        .listen('.order.new', e => {
          this.addNewlyAddedOrder(e);
        })
      ;
    },

    addNewlyAddedOrder({order}) {
      if (!order) return false;

      // add this order to orders
      this.allOrders.unshift(order);
    },

    resetFilter() {
      this.filters = {
        customer: '',
        dateFrom: null,
        dateTo: null,
        status: ''
      };

      this.allOrders = [...this.orders];
    },

    filterSetToday() {

      const today = DateFns.format(new Date(), DATE_SELECTS_FORMAT);

      this.filters.dateFrom = today;
      this.filters.dateTo = today;

      this.triggerFilter()
    },

    triggerFilter() {

      let filteredData = [...this.orders];

      for (const filter in this.filters) {

        const filterValue = this.filters[filter];

        switch (filter) {
          case 'customer':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.customer_name.toLowerCase().includes(filterValue.toLowerCase()));
          break;

          case 'status':

            if (!filterValue.length) break;

            filteredData = filteredData.filter(d => d.status == filterValue);
          break;

          case 'dateFrom':

            if (!filterValue) break;

            const fromTime = `${filterValue} 00:00:00`;

            filteredData = filteredData.filter(d => new Date(d.created_at).getTime() >= new Date(fromTime).getTime());
          break;

          case 'dateTo':

            if (!filterValue) break;

            const toTime = `${filterValue} 23:59:59`;

            filteredData = filteredData.filter(d => new Date(d.created_at).getTime() <= new Date(toTime).getTime());

          break;

          default:
            break;
        }

      }

      this.allOrders = filteredData;
    }
  }
}
</script>

<style>
  .table-badge {
    font-size: 12px;
  }

  #orders-table td {
    font-size: 16px;
    font-weight: 600;
  }

  .order-action {
    cursor: pointer;
  }
</style>
<style scoped lang="scss">
  .filters-panel {
    margin: 0 -5px;

    .filters-controls {
      background-color: #dddddd;
      padding: 5px 15px;
    }

    .filters-summery {
      background-color: #ededed;
      padding: 5px 15px;
    }
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  @media screen and (max-width:576px) {
    .filters-panel button {
      display: block;
      width: 100%;
    }
  }
</style>
