<template>
  <div>
    <slot :open-modal="openModal"></slot>

    <modal-comp
      :id="id"
      :show="showModal"
      :title="modal.title"
      :header-classes="modal.headerClasses"
      :close-btn-classes="modal.closeBtnClasses"
      @submit="handleSubmit"
      @hide="()=>{
                this.showModal=false;
                this.$emit('user-action-hide', id);
            }"
    >
      <form class="row">

        <!--offer type-->
        <div class="form-group col-6">
          <label class="col-form-label">
            Create Coupon/Discount:
            <form-input-help>Disable to create a discount</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.typeCoupon"
              @change="val=>form.typeCoupon=val"
              enable-text="Creating Coupon"
              disable-text="Creating Discount"
            />
          </div>
        </div>

        <!--coupon code-->
        <transition name="fade">
          <div
            class="form-group col-6"
            v-if="form.typeCoupon"
          >
            <label for="coupon-code" class="col-form-label">
              Coupon Code:
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="coupon-code"
              placeholder="Item category name"
              v-model.trim="form.code"
              autocomplete="off"
              required
            >
            <form-error-msg :error="formError.code" />
          </div>
        </transition>

        <!--price-->
        <div class="form-group col-6">
          <label for="price" class="col-form-label">
            Price:
            <span class="text-danger">*</span>
            <form-input-help>Price with decimals</form-input-help>
          </label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            id="price"
            placeholder="Sort position (descending)"
            v-model.trim="form.amount"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.amount" />
        </div>

        <!--price type-->
        <div class="form-group col-6">
          <label class="col-form-label">
            Price Type:
            <form-input-help>Fixed or percentage</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.fixed"
              @change="val=>form.fixed=val"
              enable-text="Fixed"
              disable-text="Percentage"
            />
          </div>
        </div>

        <!--first time-->
        <div
          v-if="form.typeCoupon"
          class="form-group col-6"
        >
          <label class="col-form-label">
            For First Order:
            <form-input-help>Enable to make this only valid for 1st order per user</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.firstTime"
              @change="val=>form.firstTime=val"
              enable-text="For 1st Order"
              disable-text="For All Orders"
            />
          </div>
        </div>

        <!--onetime-->
        <div
          v-if="form.typeCoupon"
          class="form-group col-6"
        >
          <label class="col-form-label">
            For Once:
            <form-input-help>Enable to make this valid for only once per user</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.once"
              @change="val=>form.once=val"
              enable-text="For Once"
              disable-text="For All"
            />
          </div>
        </div>

        <!--order type-->
        <div class="form-group col-6">
          <label for="order-type" class="col-form-label">
            Order Type:
          </label>
          <treeselect
            id="order-type"
            placeholder="Select order type"
            v-model="form.orderType"
            :options="options.order"
            :multiple="false"
            :searchable="false"
            :clearable="false"
          />
          <form-error-msg :error="formError.orderType" />
        </div>

        <!--minimum order-->
        <div
          :class="`form-group col-${form.typeCoupon ? '6':'12'}`"
        >
          <label for="min-order" class="col-form-label">
            Minimum Order:
            <span class="text-danger">*</span>
            <form-input-help>Minimum order amount to avail the {{
                form.typeCoupon ? 'coupon' : 'discount'
              }}
            </form-input-help>
          </label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            id="min-order"
            placeholder="Minimum order amount"
            v-model.trim="form.minOrder"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.minOrder" />
        </div>

        <div class="form-group col-12">
          <label class="col-form-label">
            Select {{ this.form.typeCoupon ? 'Coupon' : 'Discount' }} Period:
            <form-input-help>
              Select dates between when the {{ this.form.typeCoupon ? 'coupon' : 'discount' }} will be valid for
            </form-input-help>
          </label>
          <vue-date-picker
            id="from-date"
            v-model="form.dates"
            color="#007ae1"
            validate
            fullscreen-mobile
            range
            placeholder="Click to select"
            :min-date="new Date()"
          />
          <form-error-msg :error="formError.dates" />
        </div>

        <!--sort-->
        <div class="form-group col-4">
          <label for="sort" class="col-form-label">
            Sort:
            <span class="text-danger">*</span>
          </label>
          <form-input-help>Works on descending order</form-input-help>
          <input
            type="number"
            class="form-control"
            id="sort"
            placeholder="Sort position (descending)"
            v-model.trim="form.sort"
            autocomplete="off"
            required
          >
          <form-error-msg :error="formError.sort" />
        </div>

        <!--show/hide-->
        <div class="form-group col-4">
          <label class="col-form-label">
            Show / Hide:
            <form-input-help>Disabling will hide this but can be used</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.show"
              @change="val=>form.show=val"
              enable-text="Shown"
              disable-text="Hidden"
            />
          </div>
        </div>

        <!--status-->
        <div class="form-group col-4">
          <label class="col-form-label">
            Status:
            <form-input-help>Disabling will make this unusable</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="form.status"
              @change="val=>form.status=val"
              enable-text="Enabled"
              disable-text="Disabled"
            />
          </div>
        </div>

      </form>

      <template #submit-btn-content>
        <span v-if="form.submitting" class="spinner-border mr-2 align-middle"></span>
        {{ modal.submitBtnText }}
      </template>
    </modal-comp>
  </div>
</template>

<script>
import { required, minLength, maxLength, minValue, numeric, decimal, requiredIf } from 'vuelidate/lib/validators';
import ErrorHelper from '../../js/helpers/ErrorHelper';
import FormErrorMsg from '../Utility/FormErrorMsg';
import NotificationHelper from '../../js/helpers/NotificationHelper';
import Treeselect from '@riophae/vue-treeselect';
import ModalComp from '../Utility/ModalComp';
import FormInputHelp from '../Utility/FormInputHelp';
import SwitchComp from '../Utility/SwitchComp';
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import { DateFns } from '../../js/helpers/Helper';
import { DATE_SELECTS_FORMAT } from '../../js/helpers/Constants';

export default {
  name: "offer-action",
  components: {FormErrorMsg, Treeselect, ModalComp, FormInputHelp, SwitchComp, VueDatePicker},
  props: {
    id: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    offerTypes: {
      type: Array,
      required: true
    },
    orderTypes: {
      type: Array,
      required: true
    },
    offer: {
      type: [Object, Boolean]
    },

  },

  data() {
    return {
      showModal: false,
      modal: {
        title: 'New Offer',
        headerClasses: '',
        closeBtnClasses: '',
        submitBtnText: 'Add Offer'
      },
      editing: false,

      form: {
        typeCoupon: true,
        code: '',
        amount: 0.01,
        fixed: true,
        firstTime: false,
        once: false,
        orderType: 'both',
        minOrder: 10.01,
        dates: {
          start: '',
          end: ''
        },
        sort: 0,
        show: true,
        status: true,
        submitting: false
      },

      options: {
        order: [],
        payment: [],
      },

      formError: {
        code: false,
        amount: false,
        minOrder: false,
        orderType: false,
        dates: false,
        sort: false
      }
    };
  },

  validations: {
    form: {
      code: {
        required: requiredIf('typeCoupon'),
        minLength: minLength(5),
        maxLength: maxLength(20)
      },
      amount: {
        required,
        decimal,
        minValue: minValue(0.01)
      },
      minOrder: {
        required,
        decimal,
        minValue: minValue(0.01)
      },
      sort: {
        numeric
      }
    }
  },

  watch: {
    show: function (nv) {
      this.showModal = nv;
    },
    offer(nv) {

      this.editing = !!nv;

      this.populateForUpdate(nv);

    },

    'form.firstTime': function (nv) {
      // enable once if fist time is enabled
      if (nv) this.form.once = true;
    },
    'form.once': function (nv) {
      // disable first time if once is enabled
      if (!nv) this.form.firstTime = false;
    },
    'form.typeCoupon': function (nv) {
      // disable first time & once if type is not coupon
      if (!nv) {
        this.form.firstTime = false;
        this.form.once = false;
      }
    },

    // error handling
    'form.code': function () {
      this.formError.code = ErrorHelper.errAll(this.$v, 'form.code');
    },
    'form.amount': function () {
      this.formError.amount = ErrorHelper.errAll(this.$v, 'form.amount');
    },
    'form.minOrder': function () {
      this.formError.minOrder = ErrorHelper.errAll(this.$v, 'form.minOrder');
    },
    'form.sort': function () {
      this.formError.sort = ErrorHelper.errAll(this.$v, 'form.sort');
    }
  },

  mounted() {
    this.options.order = this.makeSelectOptions(this.orderTypes);
    this.setStartDate();
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    async handleSubmit() {

      // check if form has any error
      if (this.$v.$invalid) {

        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: 'Invalid Data',
          body: 'Please fill the form correctly before submitting',
          type: 'er'
        });

        return false;
      }

      const response = await this.sendData();

      if (response) {

        // show success notification
        NotificationHelper.showSimpleNotification(this.$snotify, {
          title: `Offer ${!this.editing ? 'Created' : 'Updated'}`,
          body: `Offer ${!this.editing ? 'created' : 'updated'} successfully`,
          type: 'su',
          autoHideDelay: 2,
          closeCallback: () => window.location.reload()
        });

        // close modal
        this.showModal = false;
      }
    },

    async sendData() {

      this.form.submitting = true;

      const endPoint = (this.editing)
        ? `/admin/offer/${this.offer.id}`
        : `/admin/offer`
      ;
      const method = (this.editing) ? 'patch' : 'post';

      const requestData = {
        type: this.form.typeCoupon ? 'coupon' : 'discount',
        code: this.form.typeCoupon ? this.form.code : '',
        amount: this.form.amount,
        fixed: this.form.fixed,
        first_time: this.form.firstTime,
        once: this.form.once,
        orderType: this.form.orderType,
        minOrder: this.form.minOrder,
        dates: this.form.dates,
        sort: this.form.sort,
        show: this.form.show,
        status: this.form.status
      };

      try {

        const res = await axios[method](endPoint, requestData);

        this.form.submitting = false;
        return res;

      } catch (e) {

        this.form.submitting = false;

        // show notification about error
        NotificationHelper.showServerError(this.$snotify, e);

        // map errors to display in form
        ErrorHelper.mapServerError(this.formError, e, true);

        return false;
      }

    },

    makeSelectOptions(itemsArray, append = []) {

      const options = itemsArray.map(itm => ({
        id: itm,
        label: itm
      }));

      if (append.length) {
        append.map(itm => options.push({id: itm, label: itm}));
      }

      return options;
    },

    setStartDate() {
      const today = DateFns.format(new Date(), DATE_SELECTS_FORMAT);
      const weeksLastDay = DateFns.format(DateFns.endOfWeek(new Date()), DATE_SELECTS_FORMAT);

      this.form.dates.start = today;
      this.form.dates.end = weeksLastDay;
    },

    populateForUpdate(nv) {

      if (!nv) return;

      // customize modal
      this.modal = {
        title: 'Update Offer',
        headerClasses: 'bg-warning text-dark',
        closeBtnClasses: 'text-dark',
        submitBtnText: 'Save Changes'
      };

      // populate values
      this.form.typeCoupon = (nv.type == 'coupon');
      this.form.code = nv.code;
      this.form.amount = nv.amount;
      this.form.fixed = nv.fixed;
      this.form.firstTime = nv.first_time;
      this.form.once = nv.once;
      this.form.orderType = nv.for;
      this.form.minOrder = nv.min_order;
      this.form.dates.start = nv.from;
      this.form.dates.end = nv.to;
      this.form.sort = nv.sort;
      this.form.show = nv.show;
      this.form.status = nv.status;
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
